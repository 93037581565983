import React from "react";
import { UserContext } from "../App";
import { API } from "aws-amplify";
import { Icon, Row, Col, Table } from "react-materialize";

class Projects extends React.Component {
  state = {
    projects: []
  };
  componentDidMount() {
    this.get_projects();
  }
  get_projects = async () => {
    let apiName = "inputIPs-API";
    let path = "projects";
    const projects = await API.get(apiName, path);
    this.setState({ projects });
  };

  projectDelete = async (project_id) =>{
    let projects = this.state.projects
    let pname = project_id
    projects.forEach((element) => {
      if(element.id === project_id){
        pname = element.project_name
      }
    });
    if (window.confirm("Are you sure you want to delete " + pname +"?")){
    let apiName = "inputIPs-API";
    let path = "projects/" + project_id;
     await API.del(apiName, path);
     
      projects.forEach((element, index) => {
       if(element.id === project_id){
         delete projects[index]
       }
     });
     this.setState({projects})
     this.get_projects()
    }
  }
  render() {
    return (
      <UserContext.Consumer>
        {({ user }) => (
          <>
            <Row>
              <Col className="offset-l1" l={10}>
                <Table>
                  <thead>
                    <tr>
                      <th data-field="host">Project Name</th>
                      <th data-field="targets">Targets</th>
                      <th data-field="sev">Shodan Status</th>
                      <th data-field="message">Nessus Status</th>
                      <th data-field="date">Date Started</th>
                      <th data-field="date">Overall Status</th>
                      <th data-field="report">CRA</th>
                      <th data-field="report">CSP</th>
                      <th data-field="report">Regen</th>
                      <th data-field="report">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.projects.map(project => (
                      <tr>
                        <td>{project.project_name}</td>
                        <td><span className="truncate">{project.targets !== null ? project.targets.length > 80 ? project.targets.substring(0,80) + "..." : project.targets : null}</span></td>
                        <td>
                          {project.shodan_status  ? (
                            <Icon>done</Icon>
                          ) : (
                            <Icon>directions_run</Icon>
                          )}
                        </td>
                        <td>
                          {project.nessus_status  ? (
                            <Icon>done</Icon>
                          ) : (
                            <Icon>directions_run</Icon>
                          )}
                        </td>
                        <td>{project.date}</td>
                        <td>
                          {project.report_finished ? (
                            <Icon>done_all</Icon>
                          ) : (
                            <Icon>directions_run</Icon>
                          )}
                        </td>
                        <td>
                          {project.report_finished ? (
                            [<a key={project.id} className="blue-text text-darken-2" href={"/report/" + project.id + "?report_type=cra"} ><Icon>visibility</Icon></a>]
                          ) : (
                            <Icon>visibility_off</Icon>
                          )}
                        </td>
                        <td>
                          {project.report_finished ? (
                            [<a key={project.id} className="blue-text text-darken-2" href={"/report/" + project.id + "?report_type=csp"} ><Icon>visibility</Icon></a>]
                          ) : (
                            <Icon>visibility_off</Icon>
                          )}
                        </td>
                        <td>
                          {project.report_finished ? (
                            [<a key={project.id} className="blue-text text-darken-2" href={"/report/" + project.id + "?action=regenerate"} ><Icon>visibility</Icon></a>]
                          ) : (
                            <Icon>visibility_off</Icon>
                          )}
                        </td>
                        <td>
                            <div className="red-text delete" onClick={() => this.projectDelete(project.id) }>
                              <Icon >delete</Icon>
                            </div>
                          
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </>
        )}
      </UserContext.Consumer>
    );
  }
}

export default Projects;
