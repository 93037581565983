import React from "react";
import {UserContext} from "../App";
import { Icon,  Collection, CollectionItem, Row, Col} from 'react-materialize';


class Profile extends React.Component {
  state = {

  };
  changePassword(){
    
    alert('Not working yet!');
    
    }
  render() {
    return <UserContext.Consumer>
    {/* I can use the user context to query with users context*/}
    {({user}) => 
    <>
    
<Row>
  <Col className="offset-s2" s={8}>
    
          <Collection>
          <CollectionItem><h5>Account <span className="deep-orange-text lighten-5 edit right" onClick={() => this.changePassword()}><Icon>mode_edit</Icon></span></h5></CollectionItem>
              <CollectionItem>Username: {user.username}</CollectionItem>
              <CollectionItem>Email: {user.attributes.email}</CollectionItem>
          </Collection>
  </Col>
</Row>
</>
}
</UserContext.Consumer>
  }
}

export default Profile;
