import React from 'react';
import ReactDOM from 'react-dom';
import { Auth } from 'aws-amplify';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Amplify from 'aws-amplify';
// import aws_exports from './aws-exports';
import "element-theme-default";

// Amplify.configure(aws_exports);
Amplify.configure({
    // OPTIONAL - if your API requires authentication 
    Auth: {
        // REQUIRED - Amazon Cognito Identity Pool ID
        identityPoolId: 'us-east-2:df788942-4827-4c60-83ce-59fa7c1518db',
        // REQUIRED - Amazon Cognito Region
        region: 'us-east-2', 
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'us-east-2_gsoAbL4KV', 
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '1stesq99i3ekpkhls4fj7aacfn',
        
    },
    API: {
        endpoints: [
            {
                name: "inputIPs-API",
                endpoint: "https://tltu15qhua.execute-api.us-east-2.amazonaws.com/default/",
                custom_header: async () =>{
                    return {Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`}
                }
            }
        ]
    }
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
