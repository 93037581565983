import React from "react";
import { Navbar, Icon, NavItem } from 'react-materialize';

const Nav = ({user, handleSignout}) => (
   
    <Navbar 
    alignLinks="right"
    brand={<span className="the-header grey-text" ><img alt="" src="https://mbmconsultants.co/static/imgs/mbm-consultants-logo.png" width="120" height="60"></img> ScanTron </span>}
    menuIcon={<Icon>menu</Icon>}
    options={{
      draggable: true,
      edge: 'left',
      inDuration: 250,
      onCloseEnd: null,
      onCloseStart: null,
      onOpenEnd: null,
      onOpenStart: null,
      outDuration: 200,
      preventScrolling: true,
    }}
    className="black darken-4"
  >
      <span className=" grey-text lighten-2">
      {user.attributes.email}</span>
      
      <NavItem className=" grey-text lighten-2" title="Add Project" href="/">
        <Icon>
        add
        </Icon>    
    </NavItem>
    <NavItem className=" grey-text lighten-2" title="Projects" href="/projects">
        <Icon>
        list
        </Icon>    
    </NavItem>
    <NavItem className=" grey-text lighten-2" title="Account" href="/profile">
        <Icon>
        account_box
        </Icon>    
    </NavItem>
    
    
    <NavItem title="Logout=>" onClick={handleSignout}>
        <Icon  className="grey-text lighten-2">
        exit_to_app
        </Icon>
    </NavItem>
  </Navbar>
)

export default Nav;
