import React, { Component } from 'react';
import { Authenticator, AmplifyTheme} from 'aws-amplify-react';
import { Auth, Hub } from 'aws-amplify';

import { BrowserRouter as Router, Route} from 'react-router-dom';
import './App.css';
import Nav from './components/Navbar';
import Input from './pages/Input';
import Profile from './pages/Profile';
import Projects from './pages/Projects';
import Report from './pages/Report';
// import {Container} from 'react-materialize';

// import {  SignIn } from 'aws-amplify-react';
export const UserContext = React.createContext();

const signUpConfig = {
  hideAllDefaults: true,
  signUpFields: [

    {
      label: 'Username',
      key: 'username',
      required: true,
      placeholder: 'username',
      type: 'username',
      displayOrder: 2,
    },
    {
      label: 'Email',
      key: 'email',
      required: true,
      placeholder: 'Email',
      type: 'email',
      displayOrder: 3,
    },
    {
      label: 'Password',
      key: 'password',
      required: true,
      placeholder: 'Password',
      type: 'password',
      displayOrder: 5,
    },
  ],
}
class App extends Component {
  state = {
    user: null
  }



  componentDidMount(){
    this.getUserData();
    Hub.listen('auth', data => {
      switch(data.payload.event){
        case "signIn":
          this.getUserData();
          break;
        case "signUp":
          break;
        case "signOut":
          console.log("signed out")
          this.setState({user: null})
          break;
        default:
          return;
    }
    })
  }

  getUserData = async () => {
    const user = await Auth.currentAuthenticatedUser()
    user ? this.setState({user}) : this.setState({user: null})

  }

  handleSignout = async () => {
    try {
      await Auth.signOut();
      this.setState({user: null});
    } catch (err) {
      console.error("Error signing out user", err);
    }
  };
  render(){
  const {  user } = this.state
  // console.log(this.state);
  return !user ?
  <div className="app-container">
    <Router>
      <Route  path="/" component={() => <Authenticator theme={theme} signUpConfig={signUpConfig} />}/>
      </Router>
    </div>
  //  <Authenticator theme={theme} signUpConfig={signUpConfig}/>
    :(
    <UserContext.Provider value={{user}}>
    <Router>
   <>
   <Nav user={user} handleSignout={this.handleSignout}/>
  
   <div className="app-container">
      
      <Route  exact path="/" component={Input}/>
      <Route  exact path="/profile" component={Profile}/>
      <Route  exact path="/projects" component={Projects}/>
      <Route exact path="/report"/>
      <Route path="/report/:project_id" component={Report}/>
    </div>
    </>
     </Router>
     </UserContext.Provider>
  )
  }
}


const theme = {
  
  ...AmplifyTheme,
  button:{
    ...AmplifyTheme.button,
    backgroundColor: '#616161',
    color: '#e0e0e0',
    border: '0px'
  },
  formSection:{
    ...AmplifyTheme.formSection,
    backgroundColor: '#616161',
    color: '#e0e0e0',
    border: '0px'
  },
  sectionHeader:{
    ...AmplifyTheme.sectionHeader,
    backgroundColor: '#616161',
    color: '#e0e0e0'
  },
  inputLabel:{
    ...AmplifyTheme.inputLabel,
    color: '#e0e0e0'
  },
  hint:{
    ...AmplifyTheme.hint,
    color: '#e0e0e0'
  },
  sectionFooter:{
    ...AmplifyTheme.sectionFooter,
    backgroundColor: '#616161',
    color: '#e0e0e0'
  },
}


// console.dir(theme);
export default App;