import React from "react";
import {UserContext} from "../App";
import {API } from 'aws-amplify';
import { Icon,  Collection, CollectionItem, Row, Col, TextInput, Textarea, Button} from 'react-materialize';


class Input extends React.Component {
  state = { ips:[],
    error: ""

  };
  reset = async () => {
    document.getElementById("p_name").value = '';
    document.getElementById("ips").value = '';
    document.getElementById("domains").value = '';
  }
  start_scan = async () => {
    if(document.getElementById("p_name").value && (document.getElementById("ips").value || document.getElementById("domains").value) ){
    let apiName = 'inputIPs-API';
    let path = '';
    let param = {
      body: {
        project_name  : document.getElementById("p_name").value,
        ip_addresses : document.getElementById("ips").value,
        domain_names : document.getElementById("domains").value
            }
    };
    try{
    const returned =  await API.post(apiName, path, param);
    console.log(returned);
    const ips = [returned]
    this.setState({ips})
    this.reset();
    this.sleep(15000).then(() => {
      this.setState({ips:[]})
    })
  } catch (e) {
    console.log(e)
    let error = "Scan did not start, seek help from your system admin, or look in the console log its probably a timeout or something!"
    this.setState({error})
    this.reset();
    this.sleep(15000).then(() => {
      this.setState({error:""})
    })
  }
    
    
}else{
  alert("Did you forget something?");
}}
sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}
  render() {
    return <UserContext.Consumer>
    {({user}) => 
    <>
    {this.state.ips[0] ? 
    <Row>
      <Col className="right offset-l9" s={3} l={3}>
      <div class="alert w3-container w3-center w3-animate-bottom">
        <h6>{this.state.ips[0].project} Started</h6>
        <span> <b>{this.state.ips[0].count}</b> IPs and <b>{this.state.ips[0].domains.length}</b> domains being scanned. </span>
        {user.attributes.email !== "mike@mbmconsultants.co" ? <span>thanks you!</span> : null}
      </div>

      </Col>
      
    </Row>
  : null}
  {this.state.error !== "" ?<Row>
  <Col className="right offset-l9" s={3} l={3}>
      <div class="alert w3-container w3-center w3-animate-bottom">
        <h6>Error Creating Scan</h6>
        <span> <b>{this.state.error}</b></span>
      </div>

      </Col>
  </Row> : null}
<Row>
  <Col className="offset-s2" s={8}>
    
          <Collection>
          <CollectionItem><h5><Icon>create_new_folder</Icon> Scan Form <span className="deep-orange-text lighten-5 edit right"></span></h5></CollectionItem>
          <Row>
          <Col className="offset-s1" s={8}>
          <TextInput
                id="p_name"
                label="Project Name"
                icon={<Icon>assessment</Icon>}
                l={12}
                m={12}
                s={12}
                xl={12}
              />
              </Col>
              </Row>
              <Row>
              <Col className="offset-s1" s={8}>
              <Textarea 
              id="ips"
              label="IP Addresses" 
              icon={<Icon>devices</Icon>}
              
              l={12}
              m={12}
              s={12}
              xl={12}
             />
             
             </Col>
             
              </Row>
              <Row>
              <Col className="offset-l1" l={8}>
              <TextInput
                id="domains"
                icon={<Icon>domain</Icon>}
                label="Domains"
                tooltip="Comma Separated Values"
                l={12}
                m={12}
                s={12}
                xl={12}
              />
              </Col>
              </Row>
              <Row className="center">
  
              <Button
                      onClick={() => this.reset()}
                      large
                      node="a"
                      waves="light"
                      style={{
                        marginRight: '5px',
                        marginLeft: '5px'
                      }}
                 >
                  Reset
                  </Button>
                <Button
                    onClick={() => this.start_scan()}
                    large
                    node="a"
                    waves="light"
                    
                    style={{
                      marginRight: '5px'
                    }}
                  >
                    Start Scan
                </Button>
              </Row>
          </Collection>
  </Col>
</Row>
<Row>
  <Col l={10} className="offset-l2">
  <span><Icon tiny>filter_center_focus</Icon> Project Name is Required. Name it whatever the hell you want but it will end up in the report so :man_shrugging:</span><br/>
  <span><Icon tiny>filter_center_focus</Icon> IP Addresses are Comma Separated Values (ie. 127.0.0.1,127.0.0.0/24,127.0.0.1-127.0.0.5,127.0.0.1-215)</span><br/>
  <span><Icon tiny>filter_center_focus</Icon> Domains are Comma Separated Values (ie, test.co,mbm.co) </span><br/>
  <span><Icon tiny>filter_center_focus</Icon> Must have either IPs or Domains, what are you doing here if you don't have either!</span>
  </Col>
  
</Row>
</>
}
</UserContext.Consumer>
  }
}

export default Input;
